"use client";
import { useMemo, useState } from "react";

import type { CleanStripePlansResponse, PlanDescription, StripePlanPeriod } from "@/types";
import { cn } from "@/lib/utils";
import PlanCard from "@/components/plans/subscriptions/plan-card";

import Link from "next/link";
import { GoldCoins, RightTopArrowlet, Spinner } from "@/components/icons";
import { PAGE_IDS } from "@/constants/landing";

const SubscriptionPlans = ({
  stripePlans,
  headerClassName,
}: {
  stripePlans: CleanStripePlansResponse;
  headerClassName?: string;
}) => {
  const [planPeriod, setPlanPeriod] = useState<StripePlanPeriod>("yearly");

  const mappedPlans = useMemo<PlanDescription[]>(() => {
    if (!stripePlans) return [];
    return stripePlans
      .filter((plan) => plan.type === planPeriod || plan.firebaseRole === "free" || plan.firebaseRole === "enterprise")
      .map((plan) => {
        const res: PlanDescription = {
          firebaseRole: plan.firebaseRole,
          monthlyPrice:
            plan.type === "yearly"
              ? stripePlans.find((item) => {
                  return item.firebaseRole.startsWith("pro") && item.type === "monthly";
                })?.monthlyPrice ?? 0
              : plan.monthlyPrice,
          highlighted: plan.firebaseRole.startsWith("pro"),
          showArrow: plan.firebaseRole.startsWith("pro"),
          price: plan.monthlyPrice,
          name: plan.name,
          priceId: plan.priceId,
          title: plan.name,
          subTitle: plan.subtitle ?? "Boost your study sessions",
          description:
            plan.firebaseRole === "enterprise"
              ? "Annual billing"
              : plan.type === "yearly"
                ? "Per month, billed yearly"
                : "Per month",
        };
        return res;
      });
  }, [planPeriod, stripePlans]);

  return (
    <section className="w-full">
      <div className="mx-auto max-w-[1180px] py-20 px-4 sm:px-6">
        <div className={cn("flex flex-col items-center gap-16 ", headerClassName)}>
          <h2
            id={PAGE_IDS.SUBSCRIPTION_PLANS}
            className="mx-auto max-w-2xl text-center text-[2rem] text-primitive-purple-pale-900 md:text-5xl"
          >
            Must-have learning tool even if{" "}
            <span className="font-semibold text-primitive-pink-500">
              you&apos;re on <GoldCoins /> budget
            </span>{" "}
          </h2>
          <div className="mb-12 flex gap-1">
            <button
              className="m-[2px] items-center gap-1 rounded-[100px] border border-primitive-grey-700 bg-transparent px-6 py-4 font-bold text-primitive-grey-700 data-[state=monthly]:m-0 data-[state=monthly]:border-[3px] data-[state=monthly]:border-primitive-purple-400 data-[state=monthly]:text-primitive-purple-pale-800"
              data-state={planPeriod}
              onClick={() => setPlanPeriod("monthly")}
            >
              Monthly
            </button>
            <button
              className="m-[2px] items-center gap-1 rounded-[100px] border border-primitive-grey-700 bg-transparent px-6 py-4 font-bold text-primitive-grey-700 data-[state=yearly]:m-0 data-[state=yearly]:border-[3px] data-[state=yearly]:border-primitive-purple-400 data-[state=yearly]:text-primitive-purple-pale-800"
              data-state={planPeriod}
              onClick={() => setPlanPeriod("yearly")}
            >
              Yearly{" "}
              <span className="text-decorative-red/50 data-[state=yearly]:text-decorative-red" data-state={planPeriod}>
                -20%
              </span>
            </button>
          </div>
        </div>
        <div className="">
          <div className="flex w-full flex-col justify-center gap-10 sm:flex-row">
            {mappedPlans.length === 0 && <Spinner className="h-20 w-20" />}
            {mappedPlans.map((item) => (
              <PlanCard
                highlighted={item.highlighted}
                key={item.priceId}
                period={planPeriod}
                plan={item}
                showArrow={item.showArrow}
                stripePlans={stripePlans}
              />
            ))}
          </div>
          <div className="mt-16 flex justify-center md:hidden">
            <Link
              href="mailto:hello@quino.ai"
              className="flex items-center gap-2 px-3 py-4 text-text-landing-enterprise text-primitive-purple-950"
            >
              Contact us for Enterprise plan
              <RightTopArrowlet />
            </Link>
          </div>
          <div></div>
        </div>
      </div>
    </section>
  );
};
export default SubscriptionPlans;
